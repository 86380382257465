import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import FirmamexConsole from './Console'
import LoginToken from './LoginToken'
import PrivateRoute from './shared/PrivateRoute'

function AdminContainer({match, history}) {

    return (
        <Suspense fallback={null}>
            <div id="AdminContainer">
                <Route path="/verify/:token" component={LoginToken}/>
                <PrivateRoute path="/console" component={FirmamexConsole}/>
            </div>
        </Suspense>
    )
}

export default AdminContainer;
